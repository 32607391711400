.sticky-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  html{
    background: #F4F6F9;
  }
  .max-width {
    width: 100%;
  }